(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name inscription.inscribedTeams.controller:InscribedTeamsNumberCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.phases.phase.inscribedTeams')
  .controller('InscribedTeamsNumberCtrl', InscribedTeamsNumberCtrl);

  function InscribedTeamsNumberCtrl($filter, inscribedTeamsNumber, sports, categories, genders) {
    var vm = this;
    vm.ctrlName = 'InscribedTeamsNumberCtrl';

    vm.data = {
      title: 'PHASES.INSCRIBED_TEAMS_NUMBER',
      notFoundMessage: 'RESULT_NOT_FOUND',
      headers: [
        {id: 'quantity', name: 'QUANTITY', canOrder: true},
        {id: 'sport', name: 'SPORT', canOrder: true},
        {id: 'category', name: 'CATEGORY', canOrder: true, orderBy: 'categoryOrder', orderDesc: true},
        {id: 'gender', name: 'GENDER', canOrder: true}
      ],
      /*      state: {
       //TODO
       slink: ''
       },*/
      defaultOrderBy: ['sport', 'gender', 'categoryOrder'],
      defaultTypeOrderBy: [true, true, false],
      values: parseNumberOfTeams(inscribedTeamsNumber)
    };

    function parseNumberOfTeams(data) {
      //parser de les dades per a omplir la llista
      var numberOfInscribedTeams = [];
      angular.forEach(data, function (numberOfTeams) {
        var category = findItemById(categories, numberOfTeams.categoryId);
        var newNumber = {
          quantity: numberOfTeams.quantity,
          sport: $filter('translate')('SPORTS.' + findItemById(sports, numberOfTeams.sportId).name),
          category: $filter('translate')('CATEGORIES.' + category.name),
          categoryOrder: category.startYear,
          gender: $filter('translate')(findItemById(genders, numberOfTeams.genderId).name)
        };
        numberOfInscribedTeams.push(newNumber);
      });
      return numberOfInscribedTeams;
    }


    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }
  }
}());
